import React from 'react'
// import appReducer from './appReducer.js'
import { Auth, Hub, Analytics, API, graphqlOperation, Cache /*, Logger*/ } from "aws-amplify"


const initialState = { 
  authState: 'undefined',
	albums: [], 
	albumsCached: [],
	currentPage: 0,
	currentCategory: '',
	categoryMetaField: 'caption',
	activePage: 1,
	start: 0,
	limit: 50,
	cacheLimit: 50,
	scannedCount: null,
	isLoading: 1,
	loaderView: 'Loading.', 
	searchform: {terms:'',category:'',time:new Date().getTime()},
  searchLimit: 500,
  searchNextToken: null,
	videoPlayer: {signedUrl:'', image:'', assetObj:{srcMediainfo:null,headline:'',suppCat:''}, playing:false, isDownloading: 0, hrDlSrc: ''},
	signedUrl: '',
	user: null,
	isAdmin: false,
  isUploader: false,
	isUploading: false,
  wmIsUploading: false,
	uploadCompleted: 0,
	uploadCounter: 0,
	fileDeleteCounter: 0,
	nextToken: null,
	prevToken: null,
	currToken: null,
	fileList: [],
	proxyList: [],
  archiveList: [],
	fileCountDelta: 0,
	proxyListNextToken: null,
  archiveListNextToken: null,
	isProxyProcessing: [],
	authErrorMessage: '',
	metaForm:{ yr:null, mo:null, day:null},
	toasts:{ 
      show:false, 
      display:'none', 
      displayTopPosition:'-400px', 
      title:'Toast Title', 
      time: '', 
      body:'This is your Toast body'
  },
	favorites:[],
	isMetaSaving:false,
	myDownloads:[],
  albumsByCategory:{ 
      RedCarpetEvents:{ albums:[] }, 
      News:{ albums:[] },
      CelebrityCandids:{ albums:[] },
      News:{ albums:[] },
      Sports:{ albums:[] },
      Music:{ albums:[] },
      Travel:{ albums:[] },
      Arts:{ albums:[] },
      Nature:{ albums:[] },
      CelebrityInterviews:{ albums:[] },
      Fashion:{ albums:[] },
      Aerial:{ albums:[] }
  },
  usersList:[],
  usersListForm:{userGroups:null},
  wmUploader:{
      uploadCounter:new Date().getTime(),
      isUploading:false,
      uploadCompleted:1
  },
  wmarkForm:{top: '20', left: '20', width:'',height:'', opacity: '80', layer: '10'},
  videoWatermark:{width:'',height:''},
  jobTemplate:{
      isUpdating:false,
      doJobTemplateUpdate: 0
  },
  authUiCloseButton:{show:false},
  shopCart:[],
  buySubsciptionModalShow: false,
  isSrcMediaInfoUpdating: false,
  theme:{
    bodyBgColor:"#232f3e",
    bodyTextColor:"rgba(255,255,255, .7)"
  },
  cartItems:[],
  cartLicenseOpts:[],
  shopCartTotal:0,
  isDeletingProxy: false,
  isProxyDeleting: [],
  modifiedThumbsProx: [], 
  modifiedThumbsArch: [],
  modifiedThumbPlyr: false,
  errorAlerts: {
    "UploadImageErrorAlert": { 'show': false },
    "UploadVideoErrorAlert": { 'show': false },
  },
  publicHomeCategoryLimit: 36,
  showPrivateFileList: false,
  isProxyReProcessing: [],
  hideSignInBar:false,
  downloadsModalShow: false,
  embedModalShow: false,
  salesApiTokens: { solweeToken: null},
  solweeData: {},
  solweeDownloadData:[],
  xeroConfig:{},
  xeroTokens:{},
  dateForm:{ startYr:null, startMo:null, startDay:null,endYr:null, endMo:null, endDay:null},
  photoAlbum:{photos:[]},
  photoAlbums:[],
  newPhotoAlbumName: '',
  photoUploader:{isUploading:false},
  uIdentityIdS3:null
  

}



export const AppsContext = React.createContext()

const appReducer = (state, action) => {
  switch (action.type) {
  	
    case "FILELIST":
  		return { ...state, fileList: action.fileList };
  	
    case "PROXYLIST":
  		return { ...state, proxyList: action.proxyListConnection.items, proxyListNextToken: action.proxyListConnection.nextToken, };
    
    case "ARCHIVELIST":
      return { ...state, archiveList: action.archiveListConnection.items, archiveListNextToken: action.archiveListConnection.nextToken, };
  	
    case "PROXYSTATS":
  		// console.log('action:', action)
  		return { ...state, isProxyProcessing: action.isProxyProcessing};

    case "REPROXYSTATS":
      // console.log('action:', action)
      return { ...state, isProxyReProcessing: action.isProxyReProcessing};
    
    case "MODIFIEDTHUMBSPROX":
      return { ...state, modifiedThumbsProx: action.modifiedThumbsProx};
  	
    case "MODIFIEDTHUMBSARCH":
      return { ...state, modifiedThumbsArch: action.modifiedThumbsArch};

    case "MODIFIEDTHUMBPLAYR":
      return { ...state, modifiedThumbPlyr: action.modifiedThumbPlyr};

    case "UPLOADER":
  		// console.log('action.uploader:', action.uploader)
  		const uploader = action.uploader 
  		return {...state , uploadCompleted: uploader.uploadCompleted, isUploading: uploader.isUploading, uploadCounter: uploader.uploadCounter, uploadFileName: uploader.fileName}
    
    case "WMUPLOADER":
      // console.log('action.wmUploader:', action.wmUploader)
      // const wmUploader = action.wmUploader 
      return { ...state, wmUploader: action.wmUploader}
      // return {...state , wmUploadCompleted: wmUploader.uploadCompleted, wmIsUploading: wmUploader.isUploading, uploadCounter: wmUploader.uploadCounter}
  	
    case "PHOTOUPLOADER":
      console.log('action:', action)
      return { ...state, photoUploader: action.photoUploader}

    case "JOBTEMPLATE":
      return { ...state, jobTemplate: action.jobTemplate }

    case "FILEDELETECOUNTER":
  		return {...state, fileDeleteCounter: action.fileDeleteCounter}
  	
    case "VPLAYER":
// console.log('action->', action)
  	// console.log('action.vPlayer->', action.vPlayer)
  		// const vPlayer = { ...state.videoPlayer, ...action.vPlayer }
  		return { ...state , videoPlayer: action.vPlayer }
  	
    case "SRC":
      // console.log('updating state with video srcUrl...')
      // console.log('signedUrl:', action.signedUrl)
      return { ...state, signedUrl: action.signedUrl };

    case "HRSRC":
      // console.log('updating state with video srcUrl...')
      // console.log('signedUrl:', action.signedUrl)
      return { ...state, hrSignedUrl: action.hrSignedUrl };
  	
  	case "SEARCH":
  		// state.searchform = action.searchform
  		// should record seaches here and track by user, etc.
  		return { ...state, searchform: action.searchform, isLoading:1, currentPage: 0, activePage: 1, start: 0 }
  	
  	case "SEARCHPAGE":
  		// state.activePage = action.activePage
  		// console.log('action',action)
  		return { ...state, activePage: action.activePage, isLoading:1 }
	
  	case "NEWCATEGORY":
  		let categoryFilter, categoryMetaField
  		// state.activePage = action.activePage
  		// console.log('action.currentCategory:',action.currentCategory)
  		if(action.currentCategory === 'Arts')
  			categoryFilter = `art gallery exhibit painting mural sculpture`
  			categoryMetaField = `caption`
  		
  		if(action.currentCategory === 'News') 
  			categoryFilter = `police journalist crowd protest reporter accident ufo parade ambulance fire chaos war gun`
  			categoryMetaField = `caption`
  		
  		if(action.currentCategory === 'Music')
  			categoryFilter = `concert sings perform`
  			categoryMetaField = `caption`
  		
  		if(action.currentCategory === 'Nature')
  			categoryFilter = `animal nature dogs cats volcano`
  			categoryMetaField = `caption`

  		if(action.currentCategory === 'Travel')
  			categoryFilter = `exotic paradise island landmark boat plane aerial drone`
  			categoryMetaField = `caption`

  		if(action.currentCategory === 'Sports')
  			categoryFilter = `baseball basketball football soccer tennis hockey sports`
  			categoryMetaField = `caption`

  		if(action.currentCategory === 'RedCarpetEvents') 
  			categoryFilter = `+premiere event`
  			categoryMetaField = `caption`

  		if(action.currentCategory === 'CelebrityCandids')
  			categoryFilter = `paparazzi`
  			categoryMetaField = `caption`

  		if(action.currentCategory === 'AllVideos')
  			categoryFilter = ``
  			categoryMetaField = ``

  		return { ...state, isLoading:1, currentPage: 0, activePage: 1, start: 0, currentCategory: action.currentCategory, categoryFilter: categoryFilter, categoryMetaField: categoryMetaField, nextToken: null }
  	
    case "DATEFORM":
  // console.log('action.metaForm:', action.metaForm)
      return { ...state, dateForm: action.dateForm  }

  	case "METAFORM":
  // console.log('action.metaForm:', action.metaForm)
  		return { ...state, metaForm: action.metaForm  }

    case "WMARKFORM":
    // console.log('wmarkForm action:', action)
  // console.log('action.metaForm:', action.metaForm)
      return { ...state, wmarkForm: action.wmarkForm  }
  	
    case "METAUPDATE":
  	// console.log('meta updating:', action)
  		return { ...state, isMetaSaving: action.isMetaSaving }
    
    case "SRCMEDIAINFOUPDATE":
    // console.log('meta updating:', action)
      return { ...state, isSrcMediaInfoUpdating: action.isSrcMediaInfoUpdating }

    case "PROXYDELETE":
    // console.log('meta updating:', action)
      return { ...state, isProxyDeleting: action.isProxyDeleting }
  	
  	case "PAGER":
  		// state.activePage = action.activePage
  		// console.log('action',action)
  		return { ...state, activePage: action.activePage, isLoading:1 }

  	case "SALESAPITOKENS":  		
      return { ...state, salesApiTokens: { solweeToken: action.solweeToken} }

    case "SOLWEECACHE":
      return { ...state, solweeData: action.solweeData }

    case "SOLWEE-DOWNLOAD-DATA":
      return { ...state, solweeDownloadData: action.solweeDownloadData }

    case "XEROAPI":
      return { ...state, xeroConfig:action.xeroConfig}

    case "XEROTOKENS":
      return { ...state, xeroTokens:action.xeroTokens}

    case "XEROREFRESHTOKEN":
      return { ...state, xeroRefreshToken: action.xeroRefreshToken}

    case "PAGETOKENS":
      return { ...state, prevToken: (action.prevToken) ? action.prevToken : null, currToken: (action.currToken) ? action.currToken : null, nextToken: (action.nextToken) ? action.nextToken : null}
     
    case "QUERY":
    	//cache the results
      return { ...state, albums: action.albums, isLoading:0 };

    case "CLEARALBUMS":
    	//cache the results
      return { ...state, albums: [], albumsCached: [], nextToken: null, scannedCount: 0 };
    
    case "SUBSCRIPTION":
      return { ...state, albums: [...state.albums, action.album] };

    case "QUERYCACHE":
      // console.log('action.albumConnection:', action.albumConnection)
      return { ...state, albumsCached: action.albumConnection.items, nextToken: action.albumConnection.nextToken, isLoading:0, scannedCount: action.albumConnection.scannedCount };

    case "CATEGORYCACHE":
      // console.log('action.albumConnection:', action.albumConnection)
      const category = action.category
      const newstate = { ...state }
      newstate.albumsByCategory[ category ] = { albums: action.albumConnection.items, nextToken: action.albumConnection.nextToken } 
      return { ...newstate,  isLoading:0 };

    case "CREATEVIDEOPROXY":
    	// console.log('create video proxy action:',action)
    	
    	try{
    		// addAssetDelta(action.newAsset)
    	}
    	catch(error){
    		// console.log('error:', error)
    	}
    	
    	return { ...state };

    case "VIDEOWATERMARK":
      // console.log('videoWatermark action:', action)
      return { ...state, videoWatermark: action.videoWatermark };

    case "PHOTOALBUM":
      console.log('action:', action)
      return { ...state, photoAlbum: action.photoAlbum }

    case "PHOTOALBUMQUERY":
      // console.log('action:', action)
      return { ...state, photoAlbum: action.photoAlbum }

    case "PHOTOQUERY":
      //cache the results
      // console.log('action:', action)
      return { ...state, photoAlbums: action.photoAlbums, isLoading:0 };

    case "CLEARPHOTOALBUMS":
      //cache the results
      return { ...state, photoAlbums: [], photoAlbumsCached: [], nextToken: null, scannedCount: 0 };
    
    case "ALBUMSUBSCRIPTION":
      return { ...state, photoAlbums: [...state.photoAlbums, action.photoAlbum] };

    case "PHOTOSUBSCRIPTION":
      console.log('photosubscription action:', action.photoAlbum)
      return { ...state, photoAlbum: action.photoAlbum };

    case "NEWALBUM":
      // console.log('action:', action)
      return { ...state, newAlbumFolderName: action.newAlbumFolderName };

    case "AWSUSERIDS3":
      return { ...state, uIdentityIdS3:action.uIdentityIdS3}


    //Amplify AUTH handling
    case "FETCH_USER_DATA_INIT":
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    case "FETCH_USER_DATA_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        authState: 'signedin',
        user: action.payload.user
      };
    case "FETCH_USER_DATA_FAILURE":
      return { ...state, isLoading: false, isError: true };

    case "RESET_USER_DATA":
    // console.log('reset user data.')
      return { ...state, user: null, isAdmin:false, authState:'signedout' };
    //Amplify AUTH handling

    case "AUTHERROR":
    	return { ...state, authErrorMessage: action.authErrorMessage}

    case "AUTHSTATE":
      return { ...state, authState: action.authState}

    case "BUTTONCLOSEAUTHUI":
      return { ...state, authUiCloseButton: action.authUiCloseButton}
    
    case "TOASTS":
    	// console.log('action',action)
    	return { ...state, toasts: action.toasts}

    case "ERRORALERTS":
      return { ...state, errorAlerts: action.errorAlerts}

   	case "ISADMIN":
      // console.log('action:', action)
   		return { ...state, isAdmin: action.isAdmin}

    case "ISUPLOADER":
      // console.log('action:', action)
      return { ...state, isUploader: action.isUploader}

   	case "FAVORITES":
   		return { ...state, favorites: action.favorites}

    case "SHOPCART":
      return { ...state, shopCart: action.shopCart}

    case "CARTITEMS":
    // console.log('action: ', action)
      return { ...state, cartItems: action.cartItems}
   	
    case "CARTLICENSEOPTS":
      return { ...state, cartLicenseOpts: action.cartLicenseOpts}

    case "SHOPCARTTOTAL":
    // console.log('action: ', action)
      return { ...state, shopCartTotal: action.shopCartTotal}
    
   	case "MYDOWNLOADS":
   		return { ...state, myDownloads: action.myDownloads }

    //STRIPE
    case "STRIPE":
      return { ...state, stripe: action.stripe }

    case "CHECKOUT":
      return { ...state, checkout: action.checkout, isCheckoutLoading:action.isCheckoutLoading }

    case "USERSLIST":
      // console.log('usersList:', action.usersList)
      return { ...state, usersList: action.usersList, nextToken: action.nextToken }
    
    case "USERSLISTFORM":
      // console.log('usersListForm:', action.usersListForm)
      return { ...state, usersListForm: action.usersListForm }

    case "SHOWBUYSUBMODAL":
      return { ...state, buySubsciptionModalShow: action.buySubsciptionModalShow}

    case "SHOWDOWNLOADSMODAL":
      return { ...state, downloadsModalShow: action.downloadsModalShow}

    case "SHOWEMBEDMODAL":
      return { ...state, embedModalShow: action.embedModalShow}

    case "AUTHSTATECHANGEEVENT":
      // console.log('action.nextAuthState:', action.nextAuthState)
      return { ...state, nextAuthState: action.nextAuthState}
    

    default:
      return state;
  }
};




export const AppsProvider = ({ children }) => {
  
  
  const [state, dispatch] = React.useReducer(appReducer, initialState)

//*//Amplify AUTH handling
  const [triggerFetch, setTriggerFetch] = React.useState(false);

  React.useEffect(() => {
    let isMounted = true;
    
    const fetchUserData = async () => {
      if (isMounted) {
        dispatch({ type: "FETCH_USER_DATA_INIT" });
      }
      try {
        if (isMounted) {
          const data = await Auth.currentAuthenticatedUser();
          if (data) {
            // console.log('fetched user data.')
            dispatch({
              type: "FETCH_USER_DATA_SUCCESS",
              payload: { user: data }
            });
            dispatch({ 
              type:"BUTTONCLOSEAUTHUI", 
              authUiCloseButton:{show:false}
            })
          }
        }
      } catch (error) {
        if (isMounted) {
          dispatch({ type: "FETCH_USER_DATA_FAILURE" });
        }
      }
    };

    const HubListener = () => {
      Hub.listen("auth", data => {
      	// console.log('Hub Listener for auth:', data)
        const { payload } = data;
        onAuthEvent(payload);
      });
    };

    const onAuthEvent = payload => {
    	// console.log('payload.event: ', payload.event)
      switch (payload.event) {
        case "signIn":
          if (isMounted) {
            setTriggerFetch(true);
            // console.log("hub signed in");
          }
          break;
        case "signOut":
          if (isMounted) {
            setTriggerFetch(false);
            // console.log("hub signed out");
            dispatch({ type: "RESET_USER_DATA" });
            dispatch({type:"BUTTONCLOSEAUTHUI", authUiCloseButton:{show:true}})
          }
          break;
        default:
          return;
      }
    };
    HubListener();
    fetchUserData();
    return () => {
      Hub.remove("auth");
      isMounted = false;
    };
  }, [triggerFetch]);

  const handleSignout = async () => {
    try {
      // console.log("signed out");
      await Auth.signOut();
      setTriggerFetch(false);
      dispatch({ type: "RESET_USER_DATA" });
    } catch (error) {
      // console.error("Error signing out user ", error);
    }
  };
//*///Amplify AUTH handling


// 	const createAssetDelta = `mutation createAssetDelta($input: CreateShotwireAssetsDeltaInput!) {
// 		  createShotwireAssetsDelta(input: $input) {
// 		    id
// 		    assetId
// 		    name

// 		  }
// 		}
// 		`;

// async function addAssetDelta(assetObj) {
// 	console.log('add asset delta...')
// 		const asset = {...assetObj, archived: 1}
// 	    // console.log('props:',props)
// 	    // console.log('albumid:',albumid)
// 	    // const photo = { 
// 	    //   id: id,
// 	    //   // owner: props.album.owner,
// 	    //   photoAlbumId: albumid,
// 	    //   // bucket: bucketName,
// 	    //   // thumbnail: sizes.thumbnail,
// 	    //   // fullsize: sizes.fullsize,
// 	    //   // createdAt: new Date().getTime()
// 	    // };
// 	    await API.graphql(graphqlOperation(createAssetDelta, { input: asset }));
// 	  }


  return (
    <AppsContext.Provider value={{state, dispatch, handleSignout}}>
      {children}
    </AppsContext.Provider>
  )

// return (
//     <AppsContext.Provider value={{state, dispatch}}>
//       <Authenticator hideDefault={true} theme={myCustomTheme}>
//       {children}
//       </Authenticator>
//     </AppsContext.Provider>
//   )
  
}


// 
// below example
// 

// import React from 'react'

// const INITIAL_LANGUAGE = 'english';

// export const LanguageContext = React.createContext({
//     language: INITIAL_LANGUAGE,
//     changeLanguage: () => undefined
// });

// export const LanguageProvider = (props) => {
//     const [language, setLanguage] = React.useState(INITIAL_LANGUAGE)

//     const changeLanguage = (lang) => setLanguage(lang)

//     return (
//         <LanguageContext.Provider value={{language, changeLanguage}}>
//             {props.children}
//         </LanguageContext.Provider>
//     )
// };