const colors = require('sass-extract-loader?{"plugins": ["sass-extract-js"]}!./css/_colors.scss')

export const theme = {
  breakpoints: ["480px", "768px", "1024px", "1440px"],
  space: [0, 4, 8, 16, 32],
  fonts: {
    body: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif'
  },
  fontSizes: [16, 18, 20, 22, 27, 36],
  lineHeights: {
    body: 1.45,
    heading: 1.1
  },
  colors: {
    gray: ['#efefef', '#ddd', '#333', '#111'],
    background: '#fff',
    primary: 'rebeccapurple',
    ...colors
  },
  sizes: {
    container: 1440,
    default: '100vw',
    max: '540px',
    maxPageWidth: "1440px", // Sets the max width of elements like the header/footer on really large screens
  },
  styles: {

    
  }
}

export default theme
